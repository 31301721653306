import { combineReducers } from "redux";
import { LoginUserReducers } from "../Reducers/LoginReducers";
import {
  DownloadBoxLabelReducers,
  GetSkuProductsReducers,
  GetUserProfileReducers,
  ManufacturingPackingDropdownDataReducers,
  closeBoxReducers,
  createBoxReducers,
  createMRPLabelReducers,
  getInventoryDetailsReducers,
  getProductListReducers,
  scanSerialBoxReducers,
  viewPreviousBoxReducers,
} from "../Reducers/ProductionReducers";
import {
  ChangeInspectionStatusReducers,
  CreateChallanReducers,
  CreateNewSerialCodeReducers,
  deleteChallanReducers,
  EvaluateDefectReducers,
  GetBasicQualityReducers,
  getChallanReducers,
  GetMediaReducers,
  getSettingsReducers,
  ListInspectionPointReducers,
  saveInspectionPointReducers,
  saveSettingsReducers,
  ViewDetailsReducers,
} from "../Reducers/AuditReducers";

const rootReducer = combineReducers({
  // COMMON REDUCERS
  login: LoginUserReducers,

  // PRODUCTION MODULE REDUCERS
  manufacturingDropdown: ManufacturingPackingDropdownDataReducers,
  InventoryShow: getInventoryDetailsReducers,
  ShowPreviousBox: viewPreviousBoxReducers,
  scanSerialBoxes: scanSerialBoxReducers,
  MRPLabelPdf: createMRPLabelReducers,
  ClosingBox: closeBoxReducers,
  createBox: createBoxReducers,
  boxLabel: DownloadBoxLabelReducers,
  products: GetSkuProductsReducers,
  userProfile: GetUserProfileReducers,
  productsList: getProductListReducers,

  // QUALITY MODULE REDUCERS
  basicQuality: GetBasicQualityReducers,
  Media: GetMediaReducers,
  QualityDetails: ViewDetailsReducers,
  SettingsData: getSettingsReducers,
  SaveSettings: saveSettingsReducers,
  ListInspectionRandomScan: ListInspectionPointReducers,
  saveInspectionPoint: saveInspectionPointReducers,
  changeInspectionStatus: ChangeInspectionStatusReducers,
  EvalDefects: EvaluateDefectReducers,
  NewSerialCodes: CreateNewSerialCodeReducers,
  SaveChallan: CreateChallanReducers,
  get_Challan: getChallanReducers,
  DeleteChallan: deleteChallanReducers,
});

export default rootReducer;
