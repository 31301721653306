import {
  AUDIT_LOGIN__FAILURE,
  AUDIT_LOGIN__REQUEST,
  AUDIT_LOGIN__SUCCESS,
  CLEAR_ERRORS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from "../Constants/LoginConstants";

// Login user Reducers
export const LoginUserReducers = (
  state = { login_user: {}, audit_login_user: {} },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case AUDIT_LOGIN__REQUEST:
      return {
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        login_user: action.payload,
      };
    case AUDIT_LOGIN__SUCCESS:
      return {
        ...state,
        loading: false,
        audit_login_user: action.payload,
      };
    case LOGIN_FAILURE:
    case AUDIT_LOGIN__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
