import {
  CHANGE_INSPECTION_STATUS_FAILURE,
  CHANGE_INSPECTION_STATUS_REQUEST,
  CHANGE_INSPECTION_STATUS_SUCCESS,
  CREATE_CHALLAN_FAILURE,
  CREATE_CHALLAN_REQUEST,
  CREATE_CHALLAN_SUCCESS,
  CREATE_NEW_SERIAL_CODE_FAILURE,
  CREATE_NEW_SERIAL_CODE_REQUEST,
  CREATE_NEW_SERIAL_CODE_SUCCESS,
  DELETE__CHALLAN_FAILURE,
  DELETE__CHALLAN_REQUEST,
  DELETE__CHALLAN_SUCCESS,
  EVALUATE_DEFECT_FAILURE,
  EVALUATE_DEFECT_REQUEST,
  EVALUATE_DEFECT_SUCCESS,
  GET__CHALLAN_FAILURE,
  GET__CHALLAN_REQUEST,
  GET__CHALLAN_SUCCESS,
  GET_BASIC_QUALITY_FAILURE,
  GET_BASIC_QUALITY_REQUEST,
  GET_BASIC_QUALITY_SUCCESS,
  GET_MEDIA_FAILURE,
  GET_MEDIA_REQUEST,
  GET_MEDIA_SUCCESS,
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  LIST_INSPECTION_POINT_RANDOM_SCAN_FAILURE,
  LIST_INSPECTION_POINT_RANDOM_SCAN_REQUEST,
  LIST_INSPECTION_POINT_RANDOM_SCAN_SUCCESS,
  SAVE_SETTING_FAILURE,
  SAVE_SETTING_REQUEST,
  SAVE_SETTING_SUCCESS,
  SUBMIT_INSPECTION_POINT_FAILURE,
  SUBMIT_INSPECTION_POINT_REQUEST,
  SUBMIT_INSPECTION_POINT_SUCCESS,
  VIEW_DETAILS_FAILURE,
  VIEW_DETAILS_REQUEST,
  VIEW_DETAILS_SUCCESS,
} from "../Constants/AuditConstants";
import { CLEAR_ERRORS } from "../Constants/LoginConstants";

// Get Basic Quality Reducers
export const GetBasicQualityReducers = (
  state = {
    basic_quality: {},
  },
  action
) => {
  switch (action.type) {
    case GET_BASIC_QUALITY_REQUEST:
      return {
        loading: true,
      };
    case GET_BASIC_QUALITY_SUCCESS:
      return {
        ...state,
        loading: false,
        basic_quality: action.payload,
      };
    case GET_BASIC_QUALITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Media Reducers
export const GetMediaReducers = (
  state = {
    media: {},
  },
  action
) => {
  switch (action.type) {
    case GET_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case GET_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        media: action.payload,
      };
    case GET_MEDIA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// View Details Reducers
export const ViewDetailsReducers = (
  state = {
    quality__details: {},
  },
  action
) => {
  switch (action.type) {
    case VIEW_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case VIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        quality__details: action.payload,
      };
    case VIEW_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Settings Reducers
export const getSettingsReducers = (
  state = {
    settings__arr: {},
  },
  action
) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return {
        loading: true,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings__arr: action.payload,
      };
    case GET_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Save Setting Reducers
export const saveSettingsReducers = (
  state = {
    save_settings: {},
  },
  action
) => {
  switch (action.type) {
    case SAVE_SETTING_REQUEST:
      return {
        loading: true,
      };
    case SAVE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        save_settings: action.payload,
      };
    case SAVE_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// List Inspection Point Reducers
export const ListInspectionPointReducers = (
  state = {
    random_scan: {},
  },
  action
) => {
  switch (action.type) {
    case LIST_INSPECTION_POINT_RANDOM_SCAN_REQUEST:
      return {
        loading: true,
      };
    case LIST_INSPECTION_POINT_RANDOM_SCAN_SUCCESS:
      return {
        ...state,
        loading: false,
        random_scan: action.payload,
      };
    case LIST_INSPECTION_POINT_RANDOM_SCAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Submit Inspection Reducers
export const saveInspectionPointReducers = (
  state = {
    save_Inspection: {},
  },
  action
) => {
  switch (action.type) {
    case SUBMIT_INSPECTION_POINT_REQUEST:
      return {
        loading: true,
      };
    case SUBMIT_INSPECTION_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        save_Inspection: action.payload,
      };
    case SUBMIT_INSPECTION_POINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Change Inspection Status Reducers
export const ChangeInspectionStatusReducers = (
  state = {
    changes__inspection__status: {},
  },
  action
) => {
  switch (action.type) {
    case CHANGE_INSPECTION_STATUS_REQUEST:
      return {
        loading: true,
      };
    case CHANGE_INSPECTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        changes__inspection__status: action.payload,
      };
    case CHANGE_INSPECTION_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const EvaluateDefectReducers = (
  state = {
    evaluate__defects: {},
  },
  action
) => {
  switch (action.type) {
    case EVALUATE_DEFECT_REQUEST:
      return {
        loading: true,
      };
    case EVALUATE_DEFECT_SUCCESS:
      return {
        ...state,
        loading: false,
        evaluate__defects: action.payload,
      };
    case EVALUATE_DEFECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create a New Serial Code Reducers
export const CreateNewSerialCodeReducers = (
  state = {
    NewSerial_code: {},
  },
  action
) => {
  switch (action.type) {
    case CREATE_NEW_SERIAL_CODE_REQUEST:
      return {
        loading: true,
      };
    case CREATE_NEW_SERIAL_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        NewSerial_code: action.payload,
      };
    case CREATE_NEW_SERIAL_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Challan Reducers
export const CreateChallanReducers = (
  state = {
    save_challan: {},
  },
  action
) => {
  switch (action.type) {
    case CREATE_CHALLAN_REQUEST:
      return {
        loading: true,
      };
    case CREATE_CHALLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        save_challan: action.payload,
      };
    case CREATE_CHALLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Challan Reducers
export const getChallanReducers = (
  state = {
    getChallan: {},
  },
  action
) => {
  switch (action.type) {
    case GET__CHALLAN_REQUEST:
      return {
        loading: true,
      };
    case GET__CHALLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        getChallan: action.payload,
      };
    case GET__CHALLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Delete Challan Reducers
export const deleteChallanReducers = (
  state = {
    deleteChallan: {},
  },
  action
) => {
  switch (action.type) {
    case DELETE__CHALLAN_REQUEST:
      return {
        loading: true,
      };
    case DELETE__CHALLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteChallan: action.payload,
      };
    case DELETE__CHALLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
