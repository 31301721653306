export const GET_BASIC_QUALITY_REQUEST = "GET_BASIC_QUALITY_REQUEST";
export const GET_BASIC_QUALITY_SUCCESS = "GET_BASIC_QUALITY_SUCCESS";
export const GET_BASIC_QUALITY_FAILURE = "GET_BASIC_QUALITY_FAILURE";

export const GET_MEDIA_REQUEST = "GET_MEDIA_REQUEST";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIA_FAILURE = "GET_MEDIA_FAILURE";

export const VIEW_DETAILS_REQUEST = "VIEW_DETAILS_REQUEST";
export const VIEW_DETAILS_SUCCESS = "VIEW_DETAILS_SUCCESS";
export const VIEW_DETAILS_FAILURE = "VIEW_DETAILS_FAILURE";

export const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";

export const SAVE_SETTING_REQUEST = "SAVE_SETTING_REQUEST";
export const SAVE_SETTING_SUCCESS = "SAVE_SETTING_SUCCESS";
export const SAVE_SETTING_FAILURE = "SAVE_SETTING_FAILURE";

export const LIST_INSPECTION_POINT_RANDOM_SCAN_REQUEST =
  "LIST_INSPECTION_POINT_RANDOM_SCAN_REQUEST";
export const LIST_INSPECTION_POINT_RANDOM_SCAN_SUCCESS =
  "LIST_INSPECTION_POINT_RANDOM_SCAN_SUCCESS";
export const LIST_INSPECTION_POINT_RANDOM_SCAN_FAILURE =
  "LIST_INSPECTION_POINT_RANDOM_SCAN_FAILURE";

export const SUBMIT_INSPECTION_POINT_REQUEST =
  "SUBMIT_INSPECTION_POINT_REQUEST";
export const SUBMIT_INSPECTION_POINT_SUCCESS =
  "SUBMIT_INSPECTION_POINT_SUCCESS";
export const SUBMIT_INSPECTION_POINT_FAILURE =
  "SUBMIT_INSPECTION_POINT_FAILURE";

export const CHANGE_INSPECTION_STATUS_REQUEST =
  "CHANGE_INSPECTION_STATUS_REQUEST";
export const CHANGE_INSPECTION_STATUS_SUCCESS =
  "CHANGE_INSPECTION_STATUS_SUCCESS";
export const CHANGE_INSPECTION_STATUS_FAILURE =
  "CHANGE_INSPECTION_STATUS_FAILURE";

export const EVALUATE_DEFECT_REQUEST = "EVALUATE_DEFECT_REQUEST";
export const EVALUATE_DEFECT_SUCCESS = "EVALUATE_DEFECT_SUCCESS";
export const EVALUATE_DEFECT_FAILURE = "EVALUATE_DEFECT_FAILURE";

export const CREATE_NEW_SERIAL_CODE_REQUEST = "CREATE_NEW_SERIAL_CODE_REQUEST";
export const CREATE_NEW_SERIAL_CODE_SUCCESS = "CREATE_NEW_SERIAL_CODE_SUCCESS";
export const CREATE_NEW_SERIAL_CODE_FAILURE = "CREATE_NEW_SERIAL_CODE_FAILURE";

export const CREATE_CHALLAN_REQUEST = "CREATE_CHALLAN_REQUEST";
export const CREATE_CHALLAN_SUCCESS = "CREATE_CHALLAN_SUCCESS";
export const CREATE_CHALLAN_FAILURE = "CREATE_CHALLAN_FAILURE";

export const GET__CHALLAN_REQUEST = "GET__CHALLAN_REQUEST";
export const GET__CHALLAN_SUCCESS = "GET__CHALLAN_SUCCESS";
export const GET__CHALLAN_FAILURE = "GET__CHALLAN_FAILURE";

export const DELETE__CHALLAN_REQUEST = "DELETE__CHALLAN_REQUEST";
export const DELETE__CHALLAN_SUCCESS = "DELETE__CHALLAN_SUCCESS";
export const DELETE__CHALLAN_FAILURE = "DELETE__CHALLAN_FAILURE";
