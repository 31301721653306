import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./app.scss";
import ProtectedRoutes from "./Middleware/ProtectedRoutes";
import Loading from "./Components/Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";

const Login = lazy(() => import("./Pages/Login"));
const ManufacturingPacking = lazy(() => import("./Pages/ManufacturingPackage"));
const Settings = lazy(() => import("./Pages/Settings"));
const BasicQuantity = lazy(() => import("./Pages/BasicQuantity"));
const ViewMedia = lazy(() => import("./Pages/ViewMedia"));
const ViewDetails = lazy(() => import("./Pages/ViewDetails"));
const BasicAudit = lazy(() => import("./Pages/BasicAudit"));
const CreateSerailCodeRef = lazy(() => import("./Pages/SerialCodeRef"));
const ManufactureCutting = lazy(() => import("./Pages/ManufactureCutting"));
const Journey = lazy(() => import("./Pages/Journey"));

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      { path: "/", element: <ManufacturingPacking /> },
      { path: "/settings", element: <Settings /> },
      { path: "/qualitymanager/basic-quantity", element: <BasicQuantity /> },
      {
        path: "/qualitymanager/view-media/:id/:serialCode?",
        element: <ViewMedia />,
      },
      {
        path: "/qualitymanager/view-details/:batchId",
        element: <ViewDetails />,
      },
      { path: "/qualitymanager/basic-audit/:batchId", element: <BasicAudit /> },
      { path: "/create-serial-code", element: <CreateSerailCodeRef /> },
      { path: "/manufacture-cutting", element: <ManufactureCutting /> },
      { path: "/journey/:id/:status", element: <Journey /> },
    ],
  },
]);

const App = () => {
  return (
    <Suspense
      fallback={
        <Loading color={"rgba(1, 18, 34, 1)"} size={42} height={true} />
      }
    >
      <RouterProvider
        router={router}
        future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      />
    </Suspense>
  );
};

export default App;
